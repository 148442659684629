import React from 'react';
import { Box, Heading, Text } from 'theme-ui';
import Fade from 'react-reveal/Fade';

export default function UiUx() {
  return (
    <Box sx={styles.uxui}>
      <Fade bottom>
        <Box sx={styles.boxes}>
          <Box sx={styles.box}>
            <Heading as='h3'>UX</Heading>
            <Text as='p'>User Experience</Text>
            <Box as='ul'>
              <Text as='li'>Interaction Design</Text>
              <Text as='li'>wireframes and prototypes</Text>
              <Text as='li'>information architect</Text>
              <Text as='li'>User Research</Text>
              <Text as='li'>Scenarios</Text>
            </Box>
          </Box>

          <Box sx={styles.box}>
            <Heading as='h3'>UI</Heading>
            <Text as='p'>User Interface</Text>
            <Box as='ul'>
              <Text as='li'>Visual Design</Text>
              <Text as='li'>Colors</Text>
              <Text as='li'>Graphic Designs</Text>
              <Text as='li'>Layouts</Text>
              <Text as='li'>Typography</Text>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

const styles = {
  uxui: {
    // marginTop: '4rem',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  headingContainer: {
    display: 'flex',

    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    h2: {
      fontSize: ['4rem', '6rem', '8rem'],
    },

    'h2:nth-of-type(1)': {
      color: 'primary',
    },
    'h2:nth-of-type(3)': {
      color: 'brandGreen',
    },
  },

  boxes: {
    // marginTop: '4rem',
    display: 'flex',
    width: '100%',
  },

  box: {
    padding: ['1rem', '2rem', '4rem'],
    margin: ['1rem', '2rem'],
    width: '50%',
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'flex-start',
    textAlign: 'left',
    // borderTop: 'solid 1px lightgrey',s

    h3: {
      fontSize: ['2rem', '3rem', '4rem', '6rem'],
      fontWeight: 'bold',
    },
    p: {
      color: '#888',
      marginBottom: '2rem',
    },
    ul: {
      paddingInlineStart: '0rem',
    },
  },
};
