import React from 'react';
import { Box, Heading, Text, Image } from 'theme-ui';
import Fade from 'react-reveal/Fade';
import VisionImage from '../../images/visionlaptop.png';
import PlanImage from '../../images/planningtablet.png';

export default function DesignHero2() {
  return (
    <Box sx={styles.container} id='designHero2'>
      <Box sx={styles.textContainer}>
        <Fade cascade left>
          <Heading as='h2' sx={styles.heading}>
            Imagine
            <br></br>
            <Text sx={styles.spanPrimary}>Your Vision.</Text>
          </Heading>

          <Image src={VisionImage} alt='tunnel view' />

          <Heading as='h2' sx={styles.heading2}>
            Plan
            <br></br>
            <Text sx={styles.spanBrandGreen}>The Details.</Text>
          </Heading>

          <Image src={PlanImage} alt='tunnel view' />

          <Heading as='h2' sx={styles.heading3}>
            Create
            <br></br>
            <Text sx={styles.spanSecondary}> The Brand.</Text>
          </Heading>
        </Fade>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    padding: ['0rem 2rem', '', '0rem 4rem'],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    animationDuration: '2s',
    animationIterationCount: '1',
    animationTimingFunction: 'linear',
    WebkitAnimationFillMode: 'forwards',
  },

  textContainer: {
    width: '100%',
  },
  heading: {
    fontWeight: '700',
    fontSize: ['3.25rem', '3rem', '4rem', '6rem'],
    margin: '6rem 0rem',
    textAlign: 'center',
    width: '100%',
    borderColor: 'primary',
  },

  heading2: {
    fontWeight: '700',
    fontSize: ['3.25rem', '3rem', '4rem', '6rem'],
    margin: '6rem 0rem',
    textAlign: 'center',
  },

  heading3: {
    fontWeight: '700',
    fontSize: ['3.25rem', '3rem', '4rem', '6rem'],
    margin: '6rem 0rem',
    textAlign: 'center',
  },
  spanPrimary: {
    color: 'primary',
  },
  spanSecondary: {
    color: '#24b6be',
  },
  spanBrandGreen: {
    color: '#a8c31c',
  },
};
