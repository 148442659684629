import React, { useState } from 'react';
import { background } from 'styled-system';
import { Box, Heading, Text, Image, Button } from 'theme-ui';
import Logo from '../../images/stefantrinhlogo.png';
import Fade from 'react-reveal/Fade';

const brandColors = [
  {
    colorName: 'Tech Pink',
    hexColor: '#e01f5a',
    cssColor: 'rgb(224 31 90)',
    rgbColor: '224, 31, 90',
    hslColor: 'hsl(342deg 76% 50%)',
  },
  {
    colorName: 'Code Yellow',
    hexColor: '#ecb32e',
    cssColor: 'rgb(236 179 46)',
    rgbColor: '236, 179, 46',
    hslColor: 'hsl(42deg 83% 55%)',
  },
  {
    colorName: 'Success Green',
    hexColor: '#2fb67d',
    cssColor: 'rgb(47 182 125)',
    rgbColor: '47, 182, 125',
    hslColor: 'hsl(155deg 59% 45%)',
  },
  {
    colorName: 'Binary Blue',
    hexColor: '#36c5ef',
    cssColor: 'rgb(54 197 239)',
    rgbColor: '54, 197, 239',
    hslColor: 'hsl(194deg 85% 57%)',
  },
  {
    colorName: 'Opacity White',
    hexColor: '#eaeaea',
    cssColor: 'rgb(234 234 234)',
    rgbColor: '234, 234, 234',
    hslColor: 'hsl(0deg 0% 92%)',
  },

  {
    colorName: 'Filtered Black',
    hexColor: '#202020',
    cssColor: 'rgb(32 32 32)',
    rgbColor: '32, 32, 32',
    hslColor: 'hsl(0deg 0% 13%)',
  },
];

export default function BrandGuideLines() {
  const [curentThemeColor, setCurentThemeColor] = useState({
    color: '#e01f5a !important',
  });

  return (
    <Box sx={styles.brandGuideLines}>
      <Fade bottom>
        <Box sx={styles.header}>
          <Box sx={styles.logoContainer}>
            <Image src={Logo} alt='ST' />
          </Box>

          <Box sx={styles.titleContainer}>
            <Heading as='h2'>Brand Guidelines</Heading>
            <Heading as='h4'>Version 1.0</Heading>
          </Box>
        </Box>
      </Fade>
      <Fade bottom>
        <Box sx={styles.mission}>
          <Box sx={styles.sideBarDetails}>
            <Box sx={styles.label}>
              <Heading sx={{ ...curentThemeColor }} as='h6'>
                Brand
              </Heading>
              <Text as='p'>Stefan Trinh</Text>
            </Box>
            <Box sx={styles.label}>
              <Heading sx={{ ...curentThemeColor }} as='h6'>
                Client
              </Heading>
              <Text as='p'>Self</Text>
            </Box>
            <Box sx={styles.label}>
              <Heading sx={{ ...curentThemeColor }} as='h6'>
                Industry
              </Heading>
              <Text as='p'>Software Development</Text>
            </Box>
            <Box sx={styles.label}>
              <Heading sx={{ ...curentThemeColor }} as='h6'>
                Location
              </Heading>
              <Text>Utah, USA</Text>
            </Box>
          </Box>
          <Box sx={styles.statement}>
            <Heading sx={{ ...curentThemeColor }} as='h5'>
              The Mission
            </Heading>
            <Text as='p'>
              To Produce Fantastic Software Products, That Change The World One
              Step At A Time
            </Text>
          </Box>
        </Box>
      </Fade>
      <Fade bottom>
        <Box sx={styles.brandColors}>
          <Heading sx={{ ...curentThemeColor }} as='h5'>
            Approved Colors
          </Heading>
          <Box sx={styles.colorsContainer}>
            {brandColors.map((color) => {
              return (
                <Box sx={styles.colorContainer}>
                  <Box
                    sx={{
                      ...styles.colorSample,
                      backgroundColor: color.hexColor,
                    }}
                    onClick={() =>
                      setCurentThemeColor({
                        color: `${color.hexColor} !important`,
                      })
                    }
                  />
                  <Box sx={styles.colorCodes}>
                    <Heading
                      sx={{ ...styles.colorTitle, ...curentThemeColor }}
                      as='h6'
                    >
                      {color.colorName}
                    </Heading>
                    <Text as='p'>
                      <Text sx={styles.colorCodeLabel}>RBA</Text>
                      {color.rgbColor}
                    </Text>
                    <Text as='p'>
                      <Text sx={styles.colorCodeLabel}>HSL</Text>
                      {color.hslColor}
                    </Text>
                    <Text as='p'>
                      <Text sx={styles.colorCodeLabel}>CSS</Text>
                      {color.cssColor}
                    </Text>
                    <Text as='p'>
                      <Text sx={styles.colorCodeLabel}>HEX</Text>
                      {color.hexColor}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Fade>
      <Fade bottom>
        <Box sx={styles.typography}>
          <Heading sx={{ ...curentThemeColor }} as='h5'>
            Typography
          </Heading>
          <Box>
            <Heading as='h3'>Roboto</Heading>
            <Text as='p' sx={styles.fontType}>
              Sans Serif
            </Text>
            <Text as='p' sx={styles.fontLetters}>
              Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm
              <br />
              Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
              {/* <br /> */}
              {/* ABCDEFGHIJKLMNOPQRSTUVWXYZ */}
              <br />
              1234567890
            </Text>

            <Text as='p' sx={styles.typographyDesc}>
              Roboto has a dual nature. It has a mechanical skeleton and the
              forms are largely geometric. At the same time, the font features
              friendly and open curves. While some grotesks distort their
              letterforms to force a rigid rhythm, Roboto doesn’t compromise,
              allowing letters to be settled into their natural width. This
              makes for a more natural reading rhythm more commonly found in
              humanist and serif types.
            </Text>

            <Box sx={styles.fontWeights}>
              <Box sx={styles.fontWeightBox}>
                <Heading as='h4'>Header</Heading>
                <Text as='p'>Aa</Text>
              </Box>
              <Box sx={styles.fontWeightBox}>
                <Heading as='h4'>Sub Header</Heading>
                <Text as='p'>Aa</Text>
              </Box>
              <Box sx={styles.fontWeightBox}>
                <Heading as='h4'>Body</Heading>
                <Text as='p'>Aa</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
      <Fade bottom>
        <Box sx={styles.typography}>
          <Heading sx={{ ...curentThemeColor }} as='h5'>
            Buttons
          </Heading>
          <Box sx={styles.buttonsContainer}>
            <Button variant='buttons.primary'>Primary</Button>
            <Button variant='buttons.secondary'>Secondary</Button>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

const styles = {
  brandGuideLines: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: '2rem',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    borderBottom: '1px solid lightgrey',
    padding: '2rem 0rem',
  },
  logoContainer: {
    width: ['100%', '', '25%'],

    padding: ['1.5rem 0rem', '', '0rem 1.5rem'],
    img: {
      maxWidth: ['100px', '200px'],
    },
  },

  titleContainer: {
    flexGrow: '1',
    padding: ['', '', '0rem 0rem 0rem 2rem'],
    margin: ['', '', '0 0 0 2rem'],
    h2: {
      fontSize: ['2.25rem', '2.75rem', '3.5rem', '5.5rem'],
      marginBottom: '2rem',
    },
    h4: {
      opacity: '0.5',
    },
  },

  mission: {
    width: '100%',
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    padding: ['1rem 0rem', '', '2rem 1rem'],
  },
  sideBarDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: ['row', '', 'column'],
    padding: ['0rem 0rem 1rem', '', '0rem 1rem'],
    width: ['100%', '', '30%'],
    h6: {
      color: 'primary',
      fontSize: '0.9rem',
    },
    p: { margin: '1rem 0rem' },
  },

  label: {
    margin: ['0.5rem 1.5rem 0.5rem 0rem', '', '0rem'],
  },

  statement: {
    padding: '0rem 0rem',
    width: ['100%', '', '70%'],
    borderTop: ['1px solid lightgrey', '', 'none'],
    padding: '1rem 0rem 1rem',
    h5: {
      marginBottom: '1rem',
      fontSize: '1.25rem',
      color: 'primary',
    },
    p: {
      fontSize: ['1.75rem', '', '3rem'],
    },
  },

  brandColors: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    borderTop: '1px solid lightgrey',
    padding: ['1rem 0rem', '', '2rem'],
    h5: {
      marginBottom: '1rem',
      fontSize: '1.25rem',
      color: 'primary',
    },
  },
  colorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: ['column', '', 'row'],
    width: '100%',
  },
  colorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1rem',
    // flex: '1',
    width: 'fit-content',
  },
  colorTitle: {
    marginBottom: '0.5rem',
    fontSize: '0.9rem',
    color: 'red',
  },
  colorSample: {
    width: '70px',
    position: 'relative',
    height: '70px',
    borderRadius: '500px',
    marginRight: '1rem',
    border: 'solid 1px white',
    transition: 'all ease-in-out 0.3s',
    cursor: 'pointer',
    '::before': {
      content: "''",
      width: '75px',
      height: '75px',
      border: 'solid 2px lightgrey',
      borderRadius: '500px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      position: 'absolute',
    },
    ':hover': {
      transform: 'scale(1.1)',
    },
  },
  colorCodes: {
    padding: '0.5rem',
    fontSize: '0.65rem',
    p: {
      opacity: '0.6',
    },
  },
  colorCodeLabel: {
    fontWeight: 'bold',
    marginRight: '0.5rem',
  },

  typography: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '100%',
    borderTop: '1px solid lightgrey',
    padding: ['1rem 0rem', '', '2rem'],
    h5: {
      marginBottom: '1rem',
      fontSize: '1.25rem',
      color: 'primary',
    },

    h3: {
      fontSize: ['3rem', '', '6rem'],
      marginBottom: '0rem',
      marginTop: '1rem',
    },
    p: {
      // fontSize: '2rem',
    },
  },
  fontType: {
    opacity: '0.6',
    marginBottom: '1rem',
    fontSize: ['1.5rem', '2rem'],
  },

  fontLetters: {
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  typographyDesc: {
    fontSize: '1rem',
  },

  fontWeights: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: '2rem 0rem',
    'div:nth-of-type(1) p': {
      fontWeight: '700',
    },
    'div:nth-of-type(2) p': {
      fontWeight: '400',
    },
    'div:nth-of-type(3) p': {
      fontWeight: '200',
      // fontSize: '2rem',
    },
  },

  fontWeightBox: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: ['50%', '50%', '33.3%'],
    padding: '1rem 1rem 1rem 0rem',

    h4: {
      fontSize: ['1.5rem', '2rem'],
    },
    p: {
      fontSize: ['2rem', '4rem'],
    },
  },

  buttonsContainer: {
    display: 'flex',
    width: '100%',
    button: {
      margin: '1rem 1rem 1rem 0rem',
    },
  },
};
