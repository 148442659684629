import React from 'react';
import { Box } from 'theme-ui';
import DesignHero from '../components/DesignSections/DesignHero';
import DesignHero2 from '../components/DesignSections/DesignHero2';
import BrandGuideLines from '../components/DesignSections/BrandGuideLines';
import UiUx from '../components/DesignSections/UiUx';
const Design = () => {
  return (
    <Box sx={styles.container}>
      <DesignHero />
      <DesignHero2 />
      <BrandGuideLines />
      <UiUx />
    </Box>
  );
};

export default Design;

const styles = {
  container: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
};
